import * as React from 'react'
import { Div, A } from 'glamorous'

import Spacer from 'components/spacer'
import Button from 'components/button'
import { lightBlue } from 'shared/colors'
import { showRequestDemoModal } from 'components/modal-manager'
import { sitePath } from 'shared/constants'

const HeaderElements = () => {
	return (
		<Div display="flex" alignItems="center">
			<A
				href={sitePath}
				css={{
					color: lightBlue,
					'&:hover, &:active, &:visited': { color: lightBlue },
				}}
			>
				Login
			</A>
			<Spacer width={30} />
			<Button
				color={lightBlue}
				border={`1px solid ${lightBlue}`}
				borderRadius="2px"
				onClick={() => showRequestDemoModal()}
			>
				Contact Sales
			</Button>
		</Div>
	)
}

export default HeaderElements
