import * as React from 'react'
import { Div, H1, Video } from 'glamorous'

import Spacer from 'components/spacer'
import ButtonLink from 'components/button-link'
import { lightBlue, lightOrange } from 'shared/colors'
import GettingStartedStep1 from 'imgs/om/video/GettingStartedStep1.mp4'
import { trackMarketingEvent } from 'shared/track-marketing-event'

class Step1 extends React.Component {
	render() {
		return (
			<Div
				display="flex"
				width="936px"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Div width="381px" textAlign="left">
					<H1 fontSize="44px" fontWeight="600" margin="0">
						Organize your <br />
						bid invite emails
					</H1>
					<Spacer height={30} />
					Bid invites flooding your inbox? <br />
					Import them to your bid board.
					<Spacer height={30} />
					Simply forward any bid invite to
					<Div color={lightBlue} fontWeight="600">
						invites@bidboard.buildingconnected.com
					</Div>
					<Spacer height={30} />
					We'll import your bid in seconds.
					<Spacer height={102} />
					<ButtonLink
						bgColor={lightOrange}
						width="140px"
						to="/getting-started?step=2"
					>
						Got it! Next.
					</ButtonLink>
				</Div>
				<Div width="540px">
					<Video autoPlay muted loop width="100%">
						<source src={GettingStartedStep1} type="video/mp4" />
					</Video>
				</Div>
			</Div>
		)
	}

	componentDidMount() {
		trackMarketingEvent({
			area: 'Marketing Site',
			object: 'Getting Started Guide',
			action: 'Viewed Step 1',
			name: 'GETTING_STARTED_GUIDE_STEP_1',
		})
	}
}

export default Step1
