import * as React from 'react'
import { Div, H1, Video } from 'glamorous'

import Spacer from 'components/spacer'
import { lightOrange } from 'shared/colors'
import ButtonLink from 'components/button-link'
import GettingStartedStep3 from 'imgs/om/video/GettingStartedStep3.mp4'
import { sitePath } from 'shared/constants'
import { trackMarketingEvent } from 'shared/track-marketing-event'

class Step3 extends React.Component {
	render() {
		return (
			<Div
				display="flex"
				width="936px"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Div width="381px" textAlign="left">
					<H1 fontSize="44px" fontWeight="600" margin="0">
						Never miss
						<br />a due date
					</H1>
					<Spacer height={17} />
					Once a bid is added and assigned, <br />
					it appears on the calendar.
					<Spacer height={30} />
					See important dates for the whole office <br />
					or just for yourself &ndash; all in one place!
					<Spacer height={30} />
					<strong>Tip:</strong> This calendar can sync to Gmail <br />
					or Outlook calendars.
					<Spacer height={43} />
					<ButtonLink
						bgColor={lightOrange}
						width="220px"
						href={`${sitePath}/opportunities/calendar`}
					>
						Open my calendar
					</ButtonLink>
				</Div>
				<Div width="540px">
					<Video autoPlay muted loop width="100%">
						<source src={GettingStartedStep3} type="video/mp4" />
					</Video>
				</Div>
			</Div>
		)
	}
	componentDidMount() {
		trackMarketingEvent({
			area: 'Marketing Site',
			object: 'Getting Started Guide',
			action: 'Completed',
			name: 'COMPLETED_GETTING_STARTED_GUIDE',
		})
		trackMarketingEvent({
			area: 'Marketing Site',
			object: 'Getting Started Guide',
			action: 'Viewed Step 3',
			name: 'GETTING_STARTED_GUIDE_STEP_3',
		})
	}
}

export default Step3
