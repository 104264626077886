import React from 'react'
import qs from 'query-string'
import { Div } from 'glamorous'

import Layout from 'components/layout'
import Spacer from 'components/spacer'
import { trackMarketingEvent } from 'shared/track-marketing-event'
import HeaderElements from './header-elements'
import HeroVideotron from './hero-videotron'
import ProgressBar from './progress-bar'
import Step1 from './step-1'
import Step2 from './step-2'
import Step3 from './step-3'

class GetStartedGuidePage extends React.Component {
	render() {
		const step = parseInt(qs.parse(this.props.location.search).step, 10)

		return (
			<Layout
				title="Getting Started | BuildingConnected"
				description="Ready to get started with BuildingConnected? Learn how to move your bid board to the cloud in three easy steps."
				HeaderElements={HeaderElements}
				hideNav
			>
				{step ? (
					<Div
						backgroundPosition="center"
						backgroundColor="#f4f4f4"
						height="844px"
						display="flex"
						flexDirection="column"
						alignItems="center"
						textAlign="center"
					>
						<Spacer height={90} />
						<ProgressBar step={step} />
						<Spacer height={83} />
						{step === 1 && <Step1 />}
						{step === 2 && <Step2 />}
						{step === 3 && <Step3 />}
					</Div>
				) : (
					<HeroVideotron />
				)}
			</Layout>
		)
	}

	componentDidMount() {
		trackMarketingEvent({
			area: 'Marketing Site',
			object: 'Getting Started Guide',
			action: 'Started',
			name: 'STARTED_GETTING_STARTED_GUIDE',
		})
	}
}

export default GetStartedGuidePage
