import * as React from 'react'
import { Div, Img } from 'glamorous'

import Spacer from 'components/spacer'
import { lightOrange } from 'shared/colors'
import ButtonLink from 'components/button-link'
import { trackMarketingEvent } from 'shared/track-marketing-event'
import HeroImage from 'imgs/om/getting-started-images/hero@2x.png'
import PlayIcon from 'imgs/icons/play-button.svg'
import { watchedVideoPercentage } from 'shared/wistia-methods'
import { WistiaVideoOverlay } from 'views/components'

const mqlInfo = {
	area: 'Marketing Site',
	object: 'OM Pro Video',
	action: 'Watched 75%',
	name: 'WATCHED_OM_PRO_VIDEO_75',
}

const HeroVideotron = () => {
	return (
		<Div
			backgroundPosition="center"
			backgroundColor="#1d58bc"
			height="648px"
			display="flex"
			flexDirection="column"
			alignItems="center"
			textAlign="center"
			color="#fff"
			position="relative"
		>
			<Spacer height={114} />
			<Div fontSize="54px" fontWeight="600">
				Move your bid board to the cloud
			</Div>
			<Spacer height={11} />
			<Div fontSize="21px">
				Follow three easy steps to bid smarter in no time.
			</Div>
			<Spacer height={28} />
			<ButtonLink bgColor={lightOrange} to="/getting-started?step=1">
				Learn How
			</ButtonLink>
			<Spacer height={46} />
			<Div fontSize="16px" zIndex="1">
				New to opportunity manager? Watch this short video.
			</Div>
			<Spacer height={117} />
			<Div
				position="relative"
				zIndex="1"
				onClick={() =>
					watchedVideoPercentage({
						videoId: 'zf4p8d54g0',
						percentage: 75,
						callback: () => trackMarketingEvent(mqlInfo),
					})
				}
			>
				<PlayIcon height="70px" width="70px" fill="white" />
				<WistiaVideoOverlay videoId="zf4p8d54g0" />
			</Div>
			<Img
				src={HeroImage}
				position="absolute"
				bottom="0"
				maxWidth="700px"
				width="100%"
			/>
		</Div>
	)
}

export default HeroVideotron
