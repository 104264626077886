import * as React from 'react'
import { Div } from 'glamorous'

import { navy } from 'shared/colors'
import CheckCircle from 'imgs/icons/check-circle.svg'
import Spacer from 'components/spacer'
import ButtonLink from 'components/button-link'
import NumberCircle from './number-circle'

const alignIconText = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '0 18px',
	paddingBottom: '17px',
}

const ProgressBar = ({ step }) => {
	return (
		<Div
			display="flex"
			justifyContent="space-between"
			width="936px"
			fontSize="16px"
			fontWeight="600"
			position="relative"
		>
			<Div
				borderBottom={`3px solid ${step === 1 ? navy : 'transparent'}`}
				css={alignIconText}
			>
				{step === 1 ? (
					<NumberCircle step={1} />
				) : (
					<CheckCircle width="22px" height="22px" />
				)}
				<Spacer width={9} />
				<ButtonLink
					to="/getting-started?step=1"
					display="flex"
					color={navy}
					padding="0"
				>
					Centralize your bid invites
				</ButtonLink>
			</Div>
			<Div
				borderBottom={`3px solid ${step === 2 ? navy : 'transparent'}`}
				css={alignIconText}
			>
				{step === 3 ? (
					<CheckCircle width="22px" height="22px" />
				) : (
					<NumberCircle step={2} grey={step === 1} />
				)}
				<Spacer width={9} />
				<ButtonLink
					to="/getting-started?step=2"
					display="flex"
					color={navy}
					padding="0"
				>
					Enable your estimators
				</ButtonLink>
			</Div>
			<Div
				borderBottom={`3px solid ${step === 3 ? navy : 'transparent'}`}
				css={alignIconText}
			>
				{step === 3 ? (
					<NumberCircle step={3} />
				) : (
					<NumberCircle step={3} grey />
				)}
				<Spacer width={9} />
				<ButtonLink
					to="/getting-started?step=3"
					display="flex"
					color={navy}
					padding="0"
				>
					Track your bid calendar
				</ButtonLink>
			</Div>
			<Div
				borderBottom={`1px solid ${navy}`}
				width="100%"
				position="absolute"
				bottom="1px"
			/>
		</Div>
	)
}

export default ProgressBar
