import * as React from 'react'
import { Div } from 'glamorous'
import { lightBlue, gray60 } from 'shared/colors'

const NumberCircle = ({ step, grey }) => (
	<Div
		width="22px"
		height="22px"
		borderRadius="50%"
		backgroundColor={grey ? gray60 : lightBlue}
		border={`1px solid ${grey ? gray60 : ' #a0e1ff'}`}
		color="#fff"
		fontSize="14px"
		textAlign="center"
		display="flex"
		justifyContent="center"
		alignItems="center"
	>
		{step}
	</Div>
)

export default NumberCircle
