import * as React from 'react'
import { Div, H1, Ul, Li, Video } from 'glamorous'

import Spacer from 'components/spacer'
import ButtonLink from 'components/button-link'
import { lightOrange } from 'shared/colors'
import GettingStartedStep2 from 'imgs/om/video/GettingStartedStep2.mp4'
import { trackMarketingEvent } from 'shared/track-marketing-event'

class Step2 extends React.Component {
	render() {
		return (
			<Div
				display="flex"
				width="936px"
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Div width="381px" textAlign="left">
					<H1 fontSize="44px" fontWeight="600" margin="0">
						Get more from your estimation work
					</H1>
					<Spacer height={17} />
					<Div width="329px">
						Manage your entire bidding process in one place.
						<Ul paddingLeft="20px">
							<Li>Discover other GCs bidding out your projects</Li>
							<br />
							<Li>Group ITBs by project for easier tracking</Li>
							<br />
							<Li>Assign bids to estimators to manage workload</Li>
						</Ul>
						<Spacer height={30} />
						<ButtonLink
							bgColor={lightOrange}
							width="140px"
							to="/getting-started?step=3"
						>
							Got it! Next.
						</ButtonLink>
					</Div>
				</Div>
				<Div width="540px">
					<Video autoPlay muted loop width="100%">
						<source src={GettingStartedStep2} type="video/mp4" />
					</Video>
				</Div>
			</Div>
		)
	}

	componentDidMount() {
		trackMarketingEvent({
			area: 'Marketing Site',
			object: 'Getting Started Guide',
			action: 'Viewed Step 2',
			name: 'GETTING_STARTED_GUIDE_STEP_2',
		})
	}
}

export default Step2
